import { Component } from '@angular/core';
import { Order, SubOrder } from '../interfaces/order';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NumberPipe } from '../pipes/number.pipe';
import { DateBitrixPipe } from '../pipes/date-bitrix.pipe';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateColhozPipe } from '../pipes/date-colhoz.pipe';
import { CsvService } from '../services/csv.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [DatePipe, DateColhozPipe, NumberPipe, DateBitrixPipe, NgClass, FormsModule,MatTooltipModule, NgIf],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
})
export class OrderComponent {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private csvService: CsvService,
  ) {
    authService.getCookie().subscribe((user)=>{
      let role = user.role;
      let person = user.person;
      this.name = user.name;
      if(user.parentID){
        this.parentCheck = true;
      }
      if (person !== 'Розничный покупатель') {
        this.personCheck = true;
        this.statusCheck = true;
      }
      if (role !== 'Пользователь') {
        this.statusCheck = true;
        this.roleCheck = true;
        this.personCheck = true;
      }
      
    });
    apiService
      .getOrder(activatedRoute.snapshot.params['id'])
      .subscribe((data: Order) => {
        if ('message' in data) {
          this.errorMessage = JSON.stringify(data.message);
          return;
        }
        if (data) {
          this.loading = false;
          this.errorMessage = '';
          this.order = data;
        } else {
          this.errorMessage = 'Произошла ошибка.';
        }
        console.log(data);
        if(data.responsiblePhone){
          this.responsiblePhone = data.responsiblePhone;
        }
        if(!data.order){
          this.errorMessage = 'Заказ пуст или произошла ошибка.';
          return;
        }
        this.markupCheck = data.totalPrice !== data.totalPriceMarkup;
        for(const order of data.order){
          if(order.typeF !== 'LUX кромка'){
            this.totalAmount += order.amount;
          }
        }
        this.length = data.order.length;
        this.sort('milling');
        this.checkSubOrderFields();
      });
  }

  parentCheck: boolean = false;
  markupCheck: boolean = false;
  
  MAP_TEXTURE: { [key: string]: string } = {
    '': '',
    'Вертикальная': '↑',
    'Горизонтальная': '→',
  };

  MAP_STAR: { [key: string]: string } = {
    '': '',
    'Без старения': 'Без ст.',
    'Полная патина': 'Полн.',
    'Стандтартная патина': 'Стд.',
  };

  MAP_KROMKA: { [key: string]: string } = {
    '': '',
    'Без кромки': 'Без',
    'Дизайн кромка': 'Дизайн',
    'Кромка в цвет': 'В цвет',
  };

HINGES_AMOUNT: {[key: string]: number} = {
    '2 ГОР 100': 2,
    '2 ГОР 50': 2,
    '2 Л 100': 2,
    '2 Л 50': 2,
    '2 ПР 100': 2,
    '2 ПР 50': 2,
    '3 ГОР 100': 3,
    '3 ГОР 50': 3,
    '3 Л 100': 3,
    '3 Л 50': 3,
    '3 ПР 100': 3,
    '3 ПР 50': 3,
    '4 Л 100': 4,
    '4 Л 50': 4,
    '4 ПР 100': 4,
    '4 ПР 50': 4,
    '5 Л 100': 5,
    '5 Л 50': 5,
    '5 ПР 100': 5,
    '5 ПР 50': 5
  }

  order!: Order;

  name: string = '';
  
  loading: boolean = true;
  roleCheck: boolean = false;
  statusCheck: boolean = false;
  personCheck: boolean = false;
  responsiblePhone: string = '';
  
  delimiter: boolean = false;

  // paymentDate: Date = new Date();
  //@ts-ignore;
  paymentDate: Date;
  paymentPlanForm: string = '';

  totalAmount = 0;
  length = 0;
  hingeAmount = 0;
  
  errorMessage: string = '';
  cancelMessage: string = '';

  millingCheck: boolean = false;
  frezaCheck: boolean = false;
  kromkaCheck: boolean = false;
  kromkaDesignCheck: boolean = false;
  textureCheck: boolean = false;
  starCheck: boolean = false;
  polishCheck: boolean = false;
  hingeCheck: boolean = false;


  // Sorting properties
  sortField: string = 'created'; // Default sort field
  sortOrder: 'asc' | 'desc' = 'asc'; // Default sort order
  
  checkSubOrderFields() {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка.';
      return;
    }
    for (const subOrder of this.order.order) {
      if (subOrder.milling !== undefined && subOrder.milling !== '') {
        this.millingCheck = true;
      }
      if (subOrder.freza !== undefined && subOrder.freza !== '') {
        this.frezaCheck = true;
      }
      if (subOrder.kromka !== undefined && subOrder.kromka !== '') {
        this.kromkaCheck = true;
      }
      if (subOrder.kromkaDesign !== undefined && subOrder.kromkaDesign !== '') {
        this.kromkaDesignCheck = true;
      }
      if (subOrder.texture !== undefined && subOrder.texture !== '') {
        this.textureCheck = true;
      }
      if (subOrder.star !== undefined && subOrder.star !== '') {
        this.starCheck = true;
      }
      if (subOrder.polish !== undefined && subOrder.polish !== '') {
        this.polishCheck = true;
      }
      if (subOrder.hinge && subOrder.hinge !== undefined && subOrder.hinge !== '') {
        this.hingeAmount += subOrder.amount * this.HINGES_AMOUNT[subOrder.hinge];
        this.hingeCheck = true;
      }
    }
  }

  moveSuborder(index: number, direction: 'up' | 'down') {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка';
      return;
    }
    if (direction === 'up' && index > 0) {
      // Swap the current suborder with the one above it
      [this.order.order[index], this.order.order[index - 1]] = [this.order.order[index - 1], this.order.order[index]];
    } else if (direction === 'down' && index < this.order.order.length - 1) {
      // Swap the current suborder with the one below it
      [this.order.order[index], this.order.order[index + 1]] = [this.order.order[index + 1], this.order.order[index]];
    }
  }

  sort(field: string) {
    if (this.sortField === field) {
      // Toggle sort order if the same field is clicked
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      // Set new field and default to ascending order
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.sortOrders();
  }  

  sortOrders() {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка';
      return;
    }
    this.order.order.sort((a, b) => {
      const aValue = a[this.sortField as keyof SubOrder];
      const bValue = b[this.sortField as keyof SubOrder];
  
      // Handle undefined values for the sort field
      if (aValue === undefined && bValue === undefined) {
        return 0; // Both are undefined, consider equal
      }
      if (aValue === undefined) {
        return this.sortOrder === 'asc' ? 1 : -1; // Treat undefined as greater
      }
      if (bValue === undefined) {
        return this.sortOrder === 'asc' ? -1 : 1; // Treat undefined as lesser
      }
  
      // Compare the primary sort values based on their types
      let comparison = 0;
       // For other fields, use the existing comparison logic
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          comparison = aValue.localeCompare(bValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          comparison = aValue - bValue;
        }  
      // Return the comparison result based on sort order
      return this.sortOrder === 'asc' ? comparison : -comparison;
    });
  }

  deleteOrder(){
    if(confirm("Точно удалить данный заказ?")){
      if(this.order._id){
        this.apiService.deleteOrder(this.order._id).subscribe((data)=>{
          if(data && '_id'){
            this.authService.setCookieDeletedOrder(data);
          }
          this.router.navigate(['/list']);
        });
      } else {
        this.errorMessage = "Не найден номер заказа.";
      }
    }
  }
  cancelOrder(){
    if(confirm("Отправить уведомление об отмене заказа?")){
      if(this.order._id){
        this.apiService.deleteOrder(this.order._id).subscribe((data)=>{
          //@ts-ignore
          if(data && data.message){
            //@ts-ignore
            this.cancelMessage = data.message;
          }else{
            this.authService.setCookieDeletedOrder({_id: 'Без номера'});
            this.router.navigate(['/list']);
          }
        });
      } else {
        this.errorMessage = "Не найден номер заказа.";
      }
    }
  }
  sendOrder(){
    this.errorMessage = '';
    if(!this.paymentPlanForm){
      this.errorMessage = 'Не указана форма оплаты';
      return;
    }
    if(!this.paymentDate){
      this.errorMessage = 'Не указана планируемая дата оплаты';
      return;
    }
    this.order.paymentPlanForm = this.paymentPlanForm,
    this.order.paymentPlanDate = this.paymentDate;
      
    this.apiService.createOrder(this.order).subscribe((data)=>{
      location.reload();
    })
  }
  exportToCSV(): void {
    if(!this.order.order){
      this.errorMessage = 'Заказ пуст или произошла ошибка';
      return;
    }
    const csvContent = this.csvService.jsonToCSV(this.order.order, 
      [ this.order.created,
        this.order.paymentStatus,
        this.order.dateWhenWarehouse,
        this.order.dateWhenShipping,
        this.order.customID,
        this.order.status,
        this.order.totalPrice,
        this.order.totalPriceMarkup,
        this.order.totalPriceDiscount,
        this.order.person,
        this.order.name,
        this.order.totalArea,
        this.order.responsible
      ],
      this.delimiter ? ';' : ','
    );
    this.csvService.downloadCSV(csvContent, 'data.csv');
  }

  copyOrder(){
    localStorage.setItem('order', JSON.stringify(this.order));
    this.router.navigate(['/create']);
  }
}
